import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "C:/xampp/htdocs/uniswap-org-v1/src/layouts/index.js";
import BG from '../components/bg';
import styled from 'styled-components';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Title = makeShortcode("Title");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <BG mdxType="BG" /><div style={{
      maxWidth: '650px',
      margin: '0px auto 10rem auto',
      padding: '0 1rem'
    }}>
      <Title mdxType="Title">Rissotto Privacy Policy</Title>
      <p>{`Last modified: November 23, 2022`}</p>
      <p>{`This Privacy Policy (the `}{`”`}{`Policy`}{`”`}{`) explains how (`}{`”`}{`Rissotto`}{`”`}{`, the `}{`”`}{`Company`}{`”`}{`, `}{`”`}{`we`}{`”`}{`, `}{`”`}{`us`}{`”`}{` or `}{`”`}{`our`}{`”`}{`) collects, uses, and shares data in connection with the Rissotto web app (app.rissotto.finance), `}<a parentName="p" {...{
          "href": "http://www.rissot.to"
        }}>{`www.rissot.to`}</a>{` website and all of our other properties, products, and services (the `}{`”`}{`Services`}{`”`}{`). Your use of the Services is subject to this Policy as well as our `}<a href='/terms-of-service'>{`Terms of Service`}</a>{`.`}</p>
      <p><strong parentName="p">{`Data We Collect`}</strong>{`
Privacy is central to everything we do at the Company. And we’ve enshrined transparency as one of our Company values. Accordingly, we aspire to be transparent about what little data we do collect. We do not maintain user accounts and do not collect and store personal data, such as your name or internet protocol (`}{`”`}{`IP`}{`”`}{`) address. When you interact with the Services, we collect only:
Publicly-available blockchain data. When you connect your non-custodial blockchain wallet to the Services, we collect and log your publicly-available blockchain address to learn more about your use of the Services and to screen your wallet for any prior illicit activity. We screen your wallet using intelligence provided by leading blockchain analytics providers. Note that blockchain addresses are publicly-available data that are not created or assigned by us or any central party, and by themselves are not personally identifying.`}</p>
      <p><strong parentName="p">{`Information from local storage and other tracking technologies.`}</strong>{` We and our partners may access and collect information from mobile device ID, cookies, web beacons, and other similar technologies to provide and personalize the Services and features of the Services for you across sessions. For example, we may use this information to remember tokens you import, star, or add to your shopping bag. We may also use this information to learn about your preferences, your use of the Services, and our interactions with you. Information we collect from these technologies may include things such as browser type, referring/exit pages, operating system, device or browser language, and other device information. We group and analyse these user journeys collectively, in the aggregate, to improve our product user experience.`}</p>
      <p><strong parentName="p">{`Information from other sources.`}</strong>{` We may receive information about your wallet address or transactions made through the Services from our service providers in order to comply with our legal obligations and prevent the use of our Services in connection with fraudulent or other illicit activities.`}</p>
      <p><strong parentName="p">{`Survey or usability information.`}</strong>{` If you participate in a survey or usability study with us, we will record any biographical information you directly provide to us (for example, your name, email, and job title), the responses you provide to us, and your interactions with the Services.`}</p>
      <p><strong parentName="p">{`Correspondence.`}</strong>{` We will receive any communications and information you provide directly to us via email, customer support, social media, or another support channel (such as Twitter or Discord), or when you participate in any surveys or questionnaires.`}</p>
      <p><strong parentName="p">{`Biographical information.`}</strong>{` If you apply for a job with us in time to come, we collect all information provided through our Jobs form, including name, email phone, work and immigration status, and any other resume, cover letter, or free form text you include.`}</p>
      <p><strong parentName="p">{`How We Use Data`}</strong>{`
We use the data we collect in accordance with your instructions, including any applicable terms in our `}<a href='/terms-of-service'>{`Terms of Service`}</a>{`, and as required by law. We may also use data for the following purposes:`}</p>
      <p><strong parentName="p">{`Providing the Services.`}</strong>{` We use the data we collect to provide, maintain, customise and improve our Services and features of our Services.`}</p>
      <p><strong parentName="p">{`Customer support.`}</strong>{` We may use information to provide customer support for and answer inquiries about the Services.`}</p>
      <p><strong parentName="p">{`Safety and security.`}</strong>{` We may use data to protect against, investigate, and stop fraudulent, unauthorized, or illegal activity. We may also use it to address security risks, solve potential security issues such as bugs, enforce our agreements, and protect our users and Company.`}</p>
      <p><strong parentName="p">{`Legal compliance.`}</strong>{` We may use the information we collect as needed or requested by regulators, government entities, and law enforcement to comply with applicable laws and regulations.`}</p>
      <p><strong parentName="p">{`Aggregated data.`}</strong>{` We may use some of the information we collect or access to compile aggregated data that helps us learn more about how users use the Services and where we can improve your experience.`}</p>
      <p><strong parentName="p">{`How We Share Data`}</strong>{`
We may share or disclose the data we collect:`}</p>
      <p><strong parentName="p">{`With service providers.`}</strong>{` We may share your information with our service providers and vendors to assist us in providing, delivering, and improving the Services. For example, we may share your wallet address with service providers like Infura and Cloudflare to provide technical infrastructure services, your wallet address with blockchain analytics providers to detect, prevent, and mitigate financial crime and other illicit or harmful activities, and your activity on our social media pages with our analytics provider to learn more about you interact with us and the Services.`}</p>
      <p><strong parentName="p">{`To comply with our legal obligations.`}</strong>{` We may share your data in the course of litigation, regulatory proceedings, compliance measures, and when compelled by subpoena, court order, or other legal procedure. We may also share data when we believe it is necessary to prevent harm to our users, our Company, or others, and to enforce our agreements and policies, including our `}<a href='/terms-of-service'>{`Terms of Service`}</a>{`.`}</p>
      <p><strong parentName="p">{`Safety and Security.`}</strong>{` We may share data to protect against, investigate, and stop fraudulent, unauthorized, or illegal activity. We may also use it to address security risks, solve potential security issues such as bugs, enforce our agreements, and protect our users, Company, and ecosystem.`}</p>
      <p><strong parentName="p">{`Business changes.`}</strong>{` We may transfer or share data to another entity in the event of a merger, acquisition, bankruptcy, dissolution, reorganization, asset or stock sale, or other business transaction.`}</p>
      <p><strong parentName="p">{`With your consent.`}</strong>{` We may share your information any other time you provide us with your consent to do so. We do not share your information with any third parties for any marketing purposes whatsoever.`}</p>
      <p><strong parentName="p">{`Third Party Cookies`}</strong>{`
We use services provided by Google and other third parties that use tracking technology such as cookies, device ID, and local storage, to collect information about your use of the Services and our interactions with you. You can opt out of having your online activity and device data collected through these third-party services, including by:`}</p>
      <ul>
        <li parentName="ul">{`Blocking cookies in your browser by following the instructions in your browser settings. For more information about cookies, including how to see the cookies on your device, manage them, and delete them. `}</li>
        <li parentName="ul">{`Blocking or limiting the use of your advertising ID on your mobile device through the device settings.`}</li>
        <li parentName="ul">{`Using privacy plug-ins or browsers. Certain browsers and browser extensions can be configured to block third-party cookies and trackers.`}</li>
        <li parentName="ul">{`Using the platform opt-out provided by Google at `}<a parentName="li" {...{
            "href": "https://adssettings.google.com."
          }}>{`https://adssettings.google.com.`}</a>{` You can learn more about how Google uses your information by reviewing Google’s privacy policy at `}<a parentName="li" {...{
            "href": "https://policies.google.com/privacy"
          }}>{`https://policies.google.com/privacy`}</a>{`.`}</li>
        <li parentName="ul">{`Using advertising industry opt-out tools on each device or browser where you use the Services, available at `}<a parentName="li" {...{
            "href": "http://optout.aboutads.info"
          }}>{`http://optout.aboutads.info`}</a>{` and `}<a parentName="li" {...{
            "href": "http://optout.networkadvertising.org"
          }}>{`http://optout.networkadvertising.org`}</a>{`.`}</li>
      </ul>
      <p><strong parentName="p">{`Third-Party Links and Sites`}</strong>{`
We may integrate technologies operated or controlled by other parties into parts of the Services. For example, the Services may include links that hyperlink to websites, platforms, and other services not operated or controlled by us. Please note that when you interact with these other parties, including when you leave the Site, those parties may independently collect information about you and solicit information from you. You can learn more about how those parties collect and use your data by consulting their privacy policies and other terms.`}</p>
      <p><strong parentName="p">{`Security`}</strong>{`
We implement and maintain reasonable administrative, physical, and technical security safeguards to help protect data from loss, theft, misuse, unauthorized access, disclosure, alteration, and destruction. Nevertheless, transmission via the internet is not completely secure and we cannot guarantee the security of information about you. You are responsible for all your activity on the Services, including the security of your blockchain network addresses, cryptocurrency wallets, and their cryptographic keys.`}</p>
      <p><strong parentName="p">{`Age Requirements`}</strong>{`
The Services are intended for a general audience and are not directed at children. We do not knowingly receive personal information from children. If you believe we have received personal information about a child under the age of 18, please contact us at `}<a parentName="p" {...{
          "href": "mailto:feedback@rissot.to"
        }}>{`feedback@rissot.to`}</a></p>
      <p><strong parentName="p">{`Additional Notice`}</strong>{`
Some countries require certain businesses to provide a additional notice to users to explain how we collect, use, and share their personal information, and the rights and choices we offer regarding our handling of their information.`}</p>
      <p><strong parentName="p">{`Privacy Practices.`}</strong>{` We do not `}{`”`}{`sell`}{`”`}{` personal information. Please review the `}{`”`}{`Sharing and Disclosure of Information`}{`”`}{` section above for further details about the categories of parties with whom we share information.`}</p>
      <p><strong parentName="p">{`Submitting a Request.`}</strong>{` You can submit a request for information, access, or deletion to `}<a parentName="p" {...{
          "href": "mailto:feedback@rissot.to"
        }}>{`feedback@rissot.to`}</a></p>
      <p><strong parentName="p">{`Identity Verification.`}</strong>{` We will collect and verify the identity of any individual submitting a request to access or delete personal information before providing a substantive response.`}</p>
      <p><strong parentName="p">{`Disclosures for European Union Data Subjects`}</strong>{`
We process personal data for the purposes described in the section titled `}{`”`}{`How We Use Data`}{`”`}{` above. Our bases for processing your data include: (i) you have given consent to the process to us or our service provides for one or more specific purposes; (ii) processing is necessary for the performance of a contract with you; (iii) processing is necessary for compliance with a legal obligation; and/or (iv) processing is necessary for the purposes of the legitimate interested pursued by us or a third party, and your interests and fundamental rights and freedoms do not override those interests.
Your rights under the General Data Protection Regulations (`}{`”`}{`GDPR`}{`”`}{`) include the right to (i) request access and obtain a copy of your personal data, (ii) request rectification or erasure of your personal data, (iii) object to or restrict the processing of your personal data; and (iv) request portability of your personal data. Additionally, you may withdraw your consent to our collection at any time. Nevertheless, we cannot edit or delete information that is stored on a particular blockchain. Information such as your transaction data, blockchain wallet address, and assets held by your address that may be related to the data we collect is beyond our control. To exercise any of your rights under the GDPR, please contact us at `}<a parentName="p" {...{
          "href": "mailto:feedback@rissot.to."
        }}>{`feedback@rissot.to.`}</a>{` We may require additional information from you to process your request. Please note that we may retain information as necessary to fulfill the purpose for which it was collected and may continue to do so even after a data subject request in accordance with our legitimate interests, including to comply with our legal obligations, resolves disputes, prevent fraud, and enforce our agreements.`}</p>
      <p><strong parentName="p">{`Changes to this Policy`}</strong>{`
If we make material changes to this Policy, we will notify you via the Services. Nevertheless, your continued use of the Services reflects your periodic review of this Policy and other Company terms and indicates your consent to them.`}</p>
      <p><strong parentName="p">{`Contact Us`}</strong>{`
If you have any questions about this Policy or how we collect, use, or share your information, please contact us at `}<a parentName="p" {...{
          "href": "mailto:feedback@rissot.to"
        }}>{`feedback@rissot.to`}</a></p>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      